<template>
  <div class="homer">
    <div class="container">
      <div class="heading">
        <h1>Our website is currently under construction. We ask for your understanding.</h1>
      </div>
    </div>
    <div class="container col-xl-10 homer-bg-cl align-items-center justify-content-center d-flex">
        <!--<div class="heading_info">
          Die Website von Schwella und Partner befindet sich aktuell im Umbau und wird erneuert.
        </div>-->
        <div class="row img align-items-center justify-content-center d-flex">

          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 sp_img align-items-center justify-content-center d-flex">
            <img src="../assets/SP_HDW2.jpg" alt="HDW"  class="img-responsive">
          </div>

          <div class="rapper col-sm-12 col-md-6 col-lg-5 col-xl-3 align-items-center justify-content-center d-flex">
            <div class="sp_info">
              <p><b>Adelheid Schwella</b>
              <br> Steuerberaterin Dipl.-Wirtschaftsing.
              <br>(tax consultant)
              </p>

              <p><b>Falk Schwella</b>
              <br>Steuerberater (tax consultant) Fachberater für Internationales Steuerrecht (certifiedadvisor in international taxation)</p>
              <hr>
              <p>
                <b>Address:</b> <br>
                <a href="https://goo.gl/maps/wbYDAo6fXSJRv1i28" target="_blank">Inselstr. 24 | 03046 Cottbus</a><br>
              </p>
              <hr>
              <p>
                <b class="info-nips">Phone:</b> <a href="tel:+49355355480" target="_blank">+49 (0)355-35548-0</a><br>
                <b class="info-nips">Fax:</b> <a href="tel:+49355355419" target="_blank">+49 (0)355-35548-19</a><br>
                <b class="info-nips">E-Mail:</b> <a href="mailto:info&commat;steuergestaltung&period; biz" target="_blank">info[at]steuergestaltung.biz</a><br>
              </p>
            </div>
          </div>
          <div class="rapper col-sm-12 col-md-4 col-lg-3 col-xl-2 align-items-center justify-content-center d-flex">
            <div class="sp_qr">
              <img src="../assets/SP_QR.png" alt="QR" class="img-responsive">
            </div>
          </div>

        </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {
  }
}
</script>

<style lang="scss" scoped>


.heading {
  font-size: 800;
  color: #2b6b8f;
  text-align: center;
}

.homer-bg-cl {
   background-color: rgba(234,246,251,1)
}

.sp_info {
  background-color: white;
  position: relative;
  font-weight: 500;
  font-family: 'Spectral';
  font-size: 20px;
  margin: 1vh;
  padding: 20px;
}

.info-snips {

}

.sp_qr {
  transition: transform .2s; /* Animation */
}

.sp_qr:hover {
  transform: scale(2);
}

.heading_info {
  font-size: 700;
  color: #2b6b8f;
  text-align: center;
}

.sp_img img{
  border-radius: 5vh;
  border: 20px solid transparent;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

.rapper {
  max-width: 100%;
  height: auto;
  display: block;
}

</style>